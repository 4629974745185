import  { useEffect } from 'react';

function App() {
  useEffect(() => {
    window.location.replace('https://www.papedidelivery.com');
  }, []);
  
  return (<></>);
}

export default App;
